import styled from "styled-components";
import StepsAboutUs from "./StepsAboutUs";
import ButtonMore from "./ButtonMore";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };

  return (
    <Container>
      <HeaderContainer>
        <h2>ABOUT</h2>
        <h1>APPY.YO</h1>
      </HeaderContainer>
      <InfoContainer>
        <Info>
          <h4>What we do</h4>
          <p>
            At Appy.yo, we specialize in streamlining digital transformation.
            Our approach is organized and efficient, marked by a clear process
            split into phases. From concept to launch and beyond, our
            experienced team collaborates closely with clients, turning unique
            ideas into successful projects. We stay at the forefront of
            innovation, constantly developing new and exciting ventures.
          </p>
        </Info>
        <Info>
          <h4>Our Values</h4>
          <p>
            Our commitment lies in personalized service and a tailored approach.
            We don't believe in one-size-fits-all solutions. Instead, we focus
            on understanding your business's specific needs and goals. With a
            decade of industry experience, we empower clients with efficient
            project establishment and deployment without compromising quality.
          </p>
        </Info>
        <Info>
          <h4>Our Clients</h4>
          <p>
            At Appy.yo, our priority is our clients' success. We aim to enhance
            your business's online presence by working closely with you. Let's
            connect and craft a digital journey uniquely designed for your
            success.
          </p>
        </Info>
      </InfoContainer>

      <HeaderContainer>
        <h1>Our Approach</h1>
      </HeaderContainer>

      <StepsAboutUs />

      <QuestionsContainer>
        <p>We are more than happy to answer any questions you have.</p>
        <ButtonMore buttonText="Contact us" onClick={handleClick} />
      </QuestionsContainer>
      
    </Container>
  );
};

const Container = styled.main`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const HeaderContainer = styled.div`
  margin: 4rem auto 0;
  width: 100%;

  h1 {
    color: #fff;
    font-family: RubikMonoOneRegular, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    margin: 0;
    color: #959ab2;
  }
`;

const AboutContainer = styled.div`
  width: 100vw;
  background-color: var(--color-gray);
`;

const QuestionsContainer = styled.div`
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
  text-align: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  width: 33%;
  font-size: 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4 {
    margin: 0;
    font-size: 2rem;
  }

  p {
    margin: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default About;
