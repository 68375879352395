import React from 'react';
import styled from 'styled-components';
import { useProjects } from './ProjectsContext';
import ProjectCarousel from './ProjectCarousel';

const Projects = () => {
  const projectsData = useProjects();
  return (
    <Wrapper>
      <HeaderContainer>
        <h2>RECENT</h2>
        <h1>Projects</h1>
      </HeaderContainer>
      <ProjectCarousel projectsData={projectsData} />
    </Wrapper>
  );
};

const HeaderContainer = styled.div`
  margin: 4rem auto;
  width: 80%;

  h1 {
    color: #fff;
    font-family: RubikMonoOneRegular, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    margin: 0;
    color: #959ab2;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 8rem);
`;

export default Projects;
