import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const [captchaError, setCaptchaError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    type: '',
  });

  // Shows alert message for form submission feedback
  const toggleAlert = (type) => {
    setAlertInfo({ display: true, type });

    // Hide alert after 8 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, type: '' });
    }, 8000);
  };

  const captchaRef = useRef(null);

  // Function called on submit
  const onSubmit = async (data, e) => {
    e.preventDefault();

    const token = captchaRef.current.getValue();

    if (!token) {
      setCaptchaError('Please click on the checkbox above to complete the process.');
      return;
    }

    setCaptchaError('');
    data.token = token;

    try {
      // Disable form button while processing submission
      setDisabled(true);

      // Send post request to back end
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Display success alert
        toggleAlert('success');
      } else {
        console.error('Failed to submit form');
        toggleAlert('danger');
      }

    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <ContactUsContainer>
      {!alertInfo.display && <Wrapper>
        <h2>LET`S</h2>
        <h1>CONNECT</h1>
        <Form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>

          {/* NAME */}
          <InputErrorContainer>
            <Input
              type='text'
              name='name'
              {...register('name', {
                required: { value: true, message: 'Please enter your name' },
                maxLength: {
                  value: 30,
                  message: 'Please use 30 characters or less'
                }
              })}
              className='form-control formInput'
              placeholder='Name'
            ></Input>
            {errors.name && <Error className='errorMessage'>{errors.name.message}</Error>}
          </InputErrorContainer>

          {/* EMAIL */}
          <InputErrorContainer>
            <Input
              type='email'
              name='email'
              {...register('email', {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              })}
              className='form-control formInput'
              placeholder='Email address'
            ></Input>
            {errors.email && (
              <Error className='errorMessage'>Please enter a valid email address</Error>
            )}
          </InputErrorContainer>

          {/* PHONE */}
          <InputErrorContainer>
            <Input
              type='phone'
              name='phone'
              {...register('phone', {
                required: true,
                pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
              })}
              className='form-control formInput'
              placeholder='Phone'
            ></Input>
            {errors.phone && <Error className='errorMessage'>Please enter a valid phone number</Error>}
          </InputErrorContainer>

          {/* SUBJECT OPTION */}
          <InputErrorContainer>
            <Select
              name="subject"
              id="subject"
              defaultValue=""
              {...register('subject', {
                required: true,
              })}
            >
              <option value="" disabled>Select Subject</option>
              <option value="website">Website</option>
              <option value="app">App</option>
              <option value="process">Process</option>
              <option value="project-management">Project Management</option>
              <option value="innovation-lab">Innovation Lab</option>
              <option value="other">Other</option>
            </Select>
            {errors.subject && (
              <Error className='errorMessage'>Please select one option</Error>
            )}
          </InputErrorContainer>

          {/* MESSAGE */}
          <InputErrorContainer>
            <Textarea
              rows={6}
              name='message'
              {...register('message', {
                required: { value: true, message: 'Please enter a message' },
                maxLength: {
                  value: 255,
                  message: 'Please use 255 characters or less'
                }
              })}
              className='form-control formInput'
              placeholder='Message'
            ></Textarea>
            {errors.message && <Error className='errorMessage'>{errors.message.message}</Error>}
          </InputErrorContainer>

          <div id="appyyo-captcha">
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
            {captchaError && <Error className='errorMessage'>{captchaError}</Error>}
          </div>

          {/* SUBMIT BUTTON */}
          <SubmitButton className='submit-btn' type='submit' disabled={disabled}>
            {disabled ? "Submitting..." : "Submit"}
          </SubmitButton>

        </Form>
      </Wrapper>}

      {/* FORM SUBMISSION MESSAGE */}

      {alertInfo.display &&
        <SubmitMessage role='alert'>
          {alertInfo.type === 'success' &&
            <>
              <h2>Thank you!</h2>
              <p>Your submission has been received.</p>
              <p>We will be in touch and contact you soon.</p>
              <BackToSite href="/" aria-label='Back to site'>Back to site</BackToSite>
            </>
          }
          {alertInfo.type === 'danger' &&
            <>
              <h2>Uh oh. Something went wrong.</h2>
              <BackToSite href="/" aria-label='Back to site'>Back to site</BackToSite>
            </>
          }

        </SubmitMessage>
      }

    </ContactUsContainer>
  );
};

const ContactUsContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  h1, h2 {
    margin: 0px;
  }

  h1 {
    color: #fff;
    font-family: RubikMonoOneRegular, sans-serif;
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    margin: 0;
    color: #959ab2;
  }

  @media (min-width: 1024px) {
    width: 40%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.span`
  font-size: 1rem;
  color: var(--color-yellow);
`;

const Input = styled.input`
  font-size: 1rem;
  padding: 8px;
  border: none;
  border-radius: 5px;
`;

const Select = styled.select`
  font-size: 1rem;
  padding: 0.5rem;
  border: none;

  /* styling */
  background-color: white;
  border-radius: 5px;
  display: inline-block;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    outline: 0;
  }
`;

const Textarea = styled.textarea`
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-family: Arial;
  font-size: 1rem;
  resize: none;
`;

const SubmitButton = styled.button`
  align-self: flex-start;
  padding: 0.5rem 1.5rem;
  background-color: var(--color-soft-violet);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: 0.3s;

  &:disabled {
    background-color: var(--color-soft-violet-hover);
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: var(
      --color-soft-violet-hover
    );
  }
`;

const SubmitMessage = styled.div`
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  h1, h2, p {
    margin: 0px;
    font-family: 'Quicksand', sans-serif;
  }

  p {
    font-size: 1.5rem;
  }
`;

const BackToSite = styled.a`
  padding: 0.5rem 1.5rem;
  background-color: var(--color-soft-violet);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: Quicksand;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  transition: 0.3s;

  &:hover {
    background-color: var(
      --color-soft-violet-hover
    );
  }
`;

export default Contact;

