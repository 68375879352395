import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from './GlobalStyle';
import Header from "./Components/Header";
import HomePage from "./Components/HomePage";
import Services from "./Components/Services";
import Projects from "./Components/Projects";
import Packages from "./Components/Packages.js";
import About from "./Components/About.js";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { ProjectsProvider } from './Components/ProjectsContext.js';
import AppBot from "./Components/AppBot";

const App = () => {
  return (
    <Router>
      <GlobalStyle />
        <Header />
        <ProjectsProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/services" element={<Services />} />
            <Route path="/packages" element={<Packages/>}/>
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
          </Routes>
         </ProjectsProvider>
         <AppBot />
        <Footer />

    </Router>
  );
};

export default App;


