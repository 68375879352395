import styled from "styled-components";

const packages = [
  {
    id: "mini",
    title: "Mini",
    price: "99",
    description: [
      "Mini social media updates on 2 chosen platforms",
      "Mini Website updates",
      "Mini Process Review",
    ],
  },
  {
    id: "mittel",
    title: "Mittel",
    price: "299",
    description: [
      "Mini social media updates on 2 chosen platforms",
      "Website updates",
      "Process review and advice",
      "High level Marketing plan",
    ],
  },
  {
    id: "completo",
    title: "Completo",
    price: "599",
    description: [
      "Social media posts on multiple platforms",
      "Website updates and promotional page creation",
      "Process review, advise and implementation",
      "Detailed marketing plan",
    ],
  },
];

const Packages = () => {
  return (
    <Container>
      <HeaderContainer>
        <h2>DIGITAL</h2>
        <h1>TRANSFORMATION</h1>
      </HeaderContainer>

      <TextContainer>
        <p>
          We understand that managing a project can be overwhelming and
          time-consuming, especially when it comes to digital transformation.
          That's why we offer a subscription service to our clients, where we
          can take care of all the necessary tasks while they focus on their
          product.
        </p>
        <p>
          If you're interested in learning more about our services, contact us
          to discuss your digital transformation needs or customize your plan.
        </p>
      </TextContainer>

      <PackagesContainer>
        {packages.map((item) => (
          <Package key={item.id + "_package"}>
            <h3>{item.title}</h3>
            <Price><h4>${item.price}</h4></Price>
            <Description>
              {item.description.map((text, i) => (
                <li key={i + '_package_' + item.id}>{text}</li>
              ))}
            </Description>
          </Package>
        ))}
      </PackagesContainer>
    </Container>
  );
};

const Container = styled.main`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`;

const HeaderContainer = styled.div`
  margin: 4rem auto 0;
  width: 100%;

  h1 {
    color: #fff;
    font-family: RubikMonoOneRegular, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    margin: 0;
    color: #959ab2;
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 24px;
    }
  }

  @media (min-width: 401px) and (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const TextContainer = styled.div`
  width: 100%;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    margin: 0;
  }
`;

const PackagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Package = styled.div`
  background-color: var(--color-bg-dark);
  border-radius: 30px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  flex: 1;
  transition: 0.3s;

  h3 {
    margin: 0;
    color: var(--color-purple);
  }

  &:hover {
    transition: 0.3s;
    background: #1b1b23;
    transform: scale(1.05);
  }
`;

const Price = styled.div`
  border-radius: 50%;
  border: 1px solid #fff;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    margin: 0;
    font-size: 1.5rem;
  }
`;

const Description = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0;
  padding-left: 10px;
  margin: 0;

  li {
    font-size: 1rem;
    margin: 0;
  }
`;

export default Packages;
