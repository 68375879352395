import styled from "styled-components";
import Innovation from "../Assets/innovation.gif";

const Services = () => {
  return (
    <Wrapper>
      <HeaderContainer>
        <h2>APPY.YO</h2>
        <h1>Services</h1>
      </HeaderContainer>
      <MainContainer>
        <TextContainer>
          <p>
            At Appy.Yo, we're here to turn visionary ideas into reality. Whether
            you're an independent innovator seeking infrastructure or a business
            grappling with productivity or talent needs, we've got you covered.
          </p>
          <p>
            Our innovation lab welcomes tech-driven visions for daily life
            improvements or community betterment. From providing necessary tools
            to crafting tailored solutions, we're committed to your success.
          </p>
          <p>
            Ready for a digital transformation? Let's collaborate on custom
            solutions. Contact us to discuss your unique needs and goals.
          </p>
          <p>
            Appy.Yo is dedicated to supporting your growth journey.
          </p>
        </TextContainer>
        <ServicesImage src={Innovation} alt="Innovation" />
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  margin: 4rem auto 2rem;
  width: 80%;

  h1 {
    color: #fff;
    font-family: RubikMonoOneRegular, sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    font-family: Rubik, sans-serif;
    font-weight: 400;
    margin: 0;
    color: #959ab2;
  }
`;

const MainContainer = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  width: 60%;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    margin: 0;
  }

  @media (max-width: 1023px) {
    width: 80%;
  }
`;

const ServicesImage = styled.img`
  width: 40%;
  height: auto;

  @media (max-width: 1023px) {
    width: 80%;
    height: auto;
  }
`;

export default Services;
