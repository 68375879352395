import { useEffect, useState } from "react";

export default function SubmitForm({ steps }) {
  const { name, email, phone, subject, message } = steps;
  const [formResponse, setFormResponse] = useState("");

  const data = {
    name: name.value,
    email: email.value,
    phone: phone.value,
    subject: subject.value,
    message: message.value,
  };

  // Function called on submit
  const onSubmit = async () => {
    try {
      // Send post request to back end
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/contact-bot', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        setFormResponse("Your message was submitted successfully! Thank you for contacting us!");
      } else {
        console.error("Failed to submit form");
        setFormResponse(
          "Uh oh! Failed to submit form. Please try again or use our contact us page."
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return <div>{formResponse}</div>;
}
