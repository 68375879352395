import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import { useProjects } from "../ProjectsContext";

const HomePage = () => {
  const projectsData = useProjects();

  return (
    <main>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive projectsData={projectsData} />
    </main>
  );
};

export default HomePage;
