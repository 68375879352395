import Review from "./Review";
import SubmitForm from "./SubmitForm";

const steps = [
  {
    id: "1",
    message: "Hi there! I'm AppBot, here to help!",
    trigger: "get-started",
    hideInput: true,
  },
  {
    id: "get-started",
    message: "Click the button below to get started.",
    trigger: "2"
  },
  {
    id: "2",
    options: [{ value: "askquestion", label: "I'd like to contact Appy.yo", trigger: "3" }],
    hideInput: true,
  },
  {
    id: "3",
    message:
      "You got it! I'll ask you a couple of questions first and pass your message on to our team. Let's begin!",
    trigger: "4",
  },
  {
    id: "4",
    message: "Whats your name?",
    trigger: "name",
  },
  {
    id: "name",
    user: true,
    trigger: "6",
    placeholder: "Please type your name here",
    validator: (value) => {
      if (value.length > 30) {
        return 'Please use 30 characters or less';
      } else if (value.length <= 0) {
        return 'Please enter a name';
      }
      return true;
    },
  },
  {
    id: "6",
    message: "Nice to meet you, {previousValue}! What's your email address?",
    trigger: "email",
  },
  {
    id: "email",
    user: true,
    trigger: "8",
    placeholder: "Please type your email here",
    validator: (value) => {
      const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (pattern.test(value)) {
        return true;
      } else {
        return 'Please enter a valid email address';
      }
    },
  },
  {
    id: "8",
    message: "Thanks! Please leave your phone number.",
    trigger: "phone",
  },
  {
    id: "phone",
    user: true,
    trigger: "9",
    placeholder: "Please type your phone number here",
    validator: (value) => {
      const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (pattern.test(value)) {
        return true;
      } else {
        return 'Please enter a valid phone number';
      }
    },
  },
  {
    id: "9",
    message: "Great, thanks! What can we help you with?",
    trigger: "subject",
  },
  {
    id: "subject",
    options: [
      { value: "website", label: "Website", trigger: "10" },
      { value: "app", label: "App", trigger: "10" },
      { value: "process", label: "Process", trigger: "10" },
      {
        value: "project-management",
        label: "Project Management",
        trigger: "10",
      },
      { value: "innovation-lab", label: "Innovation Lab", trigger: "10" },
      { value: "other", label: "Other", trigger: "10" },
    ],
  },
  {
    id: "10",
    message: "Sounds good! Now please leave a message so we are able to help you better.",
    trigger: "message",
  },
  {
    id: "message",
    user: true,
    trigger: "12",
    placeholder: "Please type your message here",
    validator: (value) => {
      if (value.length > 255) {
        return 'Please use 255 characters or less';
      } else if (value.length <= 0) {
        return 'Please enter a message';
      }
      return true;
    },
  },
  {
    id: "12",
    message: "Great, thank you! Check out your summary.",
    trigger: "review",
  },
  {
    id: "review",
    component: <Review />,
    asMessage: true,
    trigger: "update",
  },
  {
    id: "update",
    message: "Would you like to update any of these fields?",
    trigger: "update-question",
  },
  {
    id: "update-question",
    options: [
      { value: "yes", label: "Yes", trigger: "update-yes" },
      { value: "no", label: "No", trigger: "submit" },
    ],
  },
  {
    id: "update-yes",
    message: "What field would you like to update?",
    trigger: "update-fields",
  },
  {
    id: "update-fields",
    options: [
      { value: "name", label: "Name", trigger: "update-name-message" },
      { value: "email", label: "Email", trigger: "update-email-message" },
      { value: "phone", label: "Phone", trigger: "update-phone-message" },
      { value: "subject", label: "Subject", trigger: "update-subject-message" },
      { value: "message", label: "Message", trigger: "update-message-message" },
    ],
  },
  {
    id: "update-name-message",
    message: "Please update your name below.",
    trigger: "update-name",
  },
  {
    id: "update-name",
    update: "name",
    trigger: "12",
  },
  {
    id: "update-email-message",
    message: "Please update your email below.",
    trigger: "update-email",
  },
  {
    id: "update-email",
    update: "email",
    trigger: "12",
  },
  {
    id: "update-phone-message",
    message: "Please update your phone number below.",
    trigger: "update-phone",
  },
  {
    id: "update-phone",
    update: "phone",
    trigger: "12",
  },
  {
    id: "update-subject-message",
    message: "Please update your subject below.",
    trigger: "update-subject",
  },
  {
    id: "update-subject",
    update: "subject",
    trigger: "12",
  },
  {
    id: "update-message-message",
    message: "Please update your message below.",
    trigger: "update-message",
  },
  {
    id: "update-message",
    update: "message",
    trigger: "12",
  },
  {
    id: "submit",
    component: <SubmitForm />,
    asMessage: true,
    end: true,
  }
];

export default steps;
