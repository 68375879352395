import styled from "styled-components";

export default function SectionTwo() {
  return (
    <Container>
      <div>
        <h2>Transform your business in the digital landscape with APPY.YO</h2>
        <p>
          Our bespoke strategies are crafted to amplify your online presence and
          propel your brand's reach. Discover a partnership that's tuned to your
          unique business goals. Ready for your digital leap?
        </p>
        <h3>Let's embark on this journey together.</h3>
      </div>
    </Container>
  );
}

const Container = styled.section`
  background-color: var(--color-bg-light);
  min-height: 60vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    width: 80%;
    max-width: 1200px;
    padding: 40px;
  }

  h2 {
    font-family: 'Rubik',sans-serif;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    color: var(--color-gray);
  }

  h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    text-align: center;
  }
`;

