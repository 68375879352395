import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../Assets/FullLogo_Transparent.png";
import React, { useState } from "react";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const menuOptions = [
    { id: "home", title: "Home", href: "/" },
    { id: "projects", title: "Innovation Lab", href: "/projects" },
    { id: "services", title: "Services", href: "/services" },
    { id: "packages", title: "Packages", href: "/packages" },
    { id: "about", title: "About", href: "/about" },
    { id: "contact", title: "Contact", href: "/contact-us" },
  ];

  return (
    <HeaderContainer>
      <StyledLink to="/">
        <Logo src={LogoImg} />
      </StyledLink>
      <MobileMenuButton onClick={toggleMobileMenu}>☰</MobileMenuButton>
      <Nav $ismobilemenuopen={isMobileMenuOpen}>
        {menuOptions.map((option) => {
          return (
            <MenuOption
              to={option.href}
              key={'nav_' + option.id}
              onClick={closeMobileMenu}
            >
              {option.title}
            </MenuOption>
          );
        })}
      </Nav>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-dark);
  height: 8rem;
  padding: 0 35px;

  @media (max-width: 767px) {
    flex-direction: row;
    height: 6.5rem;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    height: 6.5rem;
  }
`;

const Logo = styled.img`
  height: 100%;
`;

const StyledLink = styled(Link)`
  height: 70%;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;

  @media (max-width: 767px) {
    z-index: 1;
    display: ${(props) => (props.$ismobilemenuopen ? "flex" : "none")};
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 30px 0;
    margin-top: 6.5rem;
    background-color: rgb(38, 38, 47);
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
    gap: 20px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    gap: 20px;
  }
`;

const MenuOption = styled(NavLink)`
  font-size: 1.5rem;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease, transform 0.2s ease;
  text-align: center;
  color: #fff;
  font-weight: 200;

  &:hover {
    color: var(--color-yellow);
    transform: scale(1.15);
  }

  &.active {
    color: var(--color-yellow);

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--color-yellow);
      bottom: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.25rem;
  }
`;

const MobileMenuButton = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 32px;
  }
`;

export default Header;
