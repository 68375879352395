import styled from "styled-components";

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 2px;
  font-weight: 600;
  font-family: "Quicksand";
`;

const Description = styled.p`
  margin: 0;
  font-family: "Quicksand";
`;

export default function Review({ steps }) {
  const { name, email, phone, subject, message } = steps;

  return (
    <ReviewContainer>
      <div>
        <Title>Name:</Title>
        <Description>{name.value}</Description>
      </div>

      <div>
        <Title>Email:</Title>
        <Description>{email.value}</Description>
      </div>

      <div>
        <Title>Phone:</Title>
        <Description>{phone.value}</Description>
      </div>

      <div>
        <Title>Subject:</Title>
        <Description>{subject.message}</Description>
      </div>

      <div>
        <Title>Message:</Title>
        <Description>{message.value}</Description>
      </div>
    </ReviewContainer>
  );
}
