import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Image from "../../Assets/appyyo.png";
import ButtonMore from "../ButtonMore";

export default function SectionThree() {
  const navigate = useNavigate();

  return (
    <Container>
      <BackgroundImage src={Image}></BackgroundImage>
      <TextContainer>
        <div>
          <h2>Unlock Potential,</h2>
          <h2>Unleash Growth.</h2>
        </div>
        <p>
          Discover tailored solutions that propel your business forward.
        </p>
        <ButtonMore
          buttonText="Learn More"
          onClick={() => navigate("/packages")}
        />
      </TextContainer>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  position: relative;
`;

const BackgroundImage = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;

  @media (max-width: 400px) {
    background-position: left;
    background-size: cover;
    width: 150%;
  }

  @media (min-width: 401px) and (max-width: 768px) { 
    background-position: left;
    background-size: cover;
    width: 150%;
  }
`
const TextContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    font-size: 2rem;
    font-family: 'RubikMonoOneRegular', sans-serif;
    margin: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 1.5rem;
    margin: 0;
  }

  @media (max-width: 400px) { 
    right: -130px;

    h2 {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media (min-width: 401px) and (max-width: 768px) {
    right: -200px;

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;