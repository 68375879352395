import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import styled, { ThemeProvider } from "styled-components";
import steps from "../bot/steps";

const ChatBotMessage = styled.div`
  font-size: 14px;
  background-color: #fff;
  color: #000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 12px;
  border-radius: 12px;
  position: fixed;
  bottom: 110px;
  right: 32px;
  z-index: 4;
`;

export default function AppBot() {
  const [botIsOpen, setBotIsOpen] = useState(false);
  const [botMessage, setBotMessage] = useState(false);
  // ensures bot greeting only shows once
  const [botHasBeenOpened, setBotHasBeenOpened] = useState(false);

  const toggleBot = () => {
    setBotIsOpen(!botIsOpen);
    setBotMessage(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!botIsOpen && !botHasBeenOpened) {
        setBotMessage(true);
        setBotHasBeenOpened(true);
      }
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [botIsOpen, botHasBeenOpened]);

  const theme = {
    background: "#f5f8fb",
    headerBgColor: "#5553A4",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#5553A4",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  return (
    <ThemeProvider theme={theme}>
      {botMessage && (
        <ChatBotMessage>Hi there! I'm AppBot here to help!</ChatBotMessage>
      )}
      <ChatBot
        floating={true}
        headerTitle="Conversation with AppBot"
        userDelay="0"
        steps={steps}
        bubbleStyle={{ wordWrap: "break-word", boxSizing: 'content-box' }}
        avatarStyle={{ boxSizing: 'content-box' }}
        placeholder="Type your message here"
        bubbleOptionStyle={{ cursor: "pointer", fontFamily: "Quicksand"}}
        opened={botIsOpen}
        toggleFloating={toggleBot}
      />
    </ThemeProvider>
  );
}
