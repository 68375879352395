import React from "react";
import styled from "styled-components";

const StepsAboutUs = () => {
  const steps = [
    {
      number: "1",
      title: "Research",
      description:
        "We take an in-depth look at your business, engage in detailed discussions around your needs and wants, and set clear goals.",
    },
    {
      number: "2",
      title: "Design",
      description:
        "Combining research, goals, and needs, we create a visual representation of the end product. We ensure that we capture all your comments and answer any questions you may have during the review process.",
    },
    {
      number: "3",
      title: "Build",
      description:
        "Our team of experts gets to work on bringing your plans to reality. We use the latest tools and technologies to ensure a high-quality and efficient development process. Our team provides regular updates and progress reports, keeping you informed every step of the way.",
    },
    {
      number: "4",
      title: "Test",
      description:
        "Once the build is complete, we conduct a thorough testing cycle to ensure that the product is fully functional and meets your expectations. We address any issues that may arise during testing, ensuring that the end product is of the highest quality.",
    },
    {
      number: "5",
      title: "Delivery",
      description:
        "Upon successful completion of testing, we deliver the product to you, providing all the necessary documentation and training to ensure a smooth transition. We're committed to ensuring that the delivery process is stress-free and straightforward.",
    },
  ];

  return (
    <Container>
      {steps.map((step, i) => (
        <StepContainer key={i + step.number} style={{ alignSelf: i % 2 ? 'end' : 'start' } }>
          <h3>{step.number}</h3>
          <StepDetails>
            <h4>{step.title}</h4>
            <p>{step.description}</p>
          </StepDetails>
        </StepContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  gap: 3rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  gap: 2rem;


  h3 {
    margin: 0;
    font-family: 'Rubik',sans-serif;
    font-size: 12rem;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    // flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;

    h3 {
      font-size: 5rem;
    }
  }
`;

const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-light);
  border-radius: 30px;
  padding: 4rem;

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1rem;
  }

  @media (max-width: 767px) {
    padding: 2rem;
  }
`;

export default StepsAboutUs;
