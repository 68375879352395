import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ButtonMore from "../ButtonMore";

export default function SectionFour() {
  const navigate = useNavigate();
  return (
    <Container>
      <h2>From Concept to Creation</h2>
      <p>
        Appy.Yo is your gateway to making great ideas happen with easy-to-use
        tech tools
      </p>
      <ButtonMore buttonText="Learn More" onClick={() => navigate("/about")} />
    </Container>
  )
}

const Container = styled.section`
  min-height: 40vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  padding: 40px;

  h2 {
    font-family: 'Rubik',sans-serif;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 0;
  }

  p {
    font-size: 1.25rem;
    text-align: center;
  }
`;