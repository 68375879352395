import React, { createContext, useContext } from 'react';
import projectsData from './projectsData';

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  return (
    <ProjectsContext.Provider value={projectsData}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  return useContext(ProjectsContext);
};
