import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProjectDetails from "./HomePage/ProjectDetails";
import styled from "styled-components";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    showDots: true
  },
};

export default function ProjectCarousel({ projectsData }) {
  return (
    <CarouselContainer
      responsive={responsive}
      infinite={true}
      swipeable={true}
      draggable={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {projectsData.map((project, index) => (
        <ProjectContainer key={project.id + index + "project"}>
          <ProjectDetails project={project} />
        </ProjectContainer>
      ))}
    </CarouselContainer>
  );
}

const ProjectContainer = styled.div`
  width: 70%;
  height: 100%;
  margin: 0 auto;
  border-radius: 30px;

  div {
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 100%;
  }
`;

const CarouselContainer = styled(Carousel)`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;
