import styled from "styled-components";
import comingSoonIcon from "../../Assets/comingSoonIcon.svg";
import { Link } from "react-router-dom";

export default function ProjectDetails({ project }) {
  return (
    <ProjectContainer>
      <ProjectImage src={project.image || comingSoonIcon} />
      <ProjectText>
        <h4>{project.title}</h4>
        <p>{project.description}</p>
        {project.showVisitButton && (
          <YellowButton to={project.link || ""} target="blank">
            Visit {project.title}
          </YellowButton>
        )}
      </ProjectText>
      <Box id="box" />
    </ProjectContainer>
  );
}

const ProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  z-index: 2;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
    border-radius: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    padding: 20px;
    height: 100%;
  }
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 90%;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
    z-index: 1;

  @media (max-width: 768px) {
    width: 100%;
    display; none;
  }
`;

const ProjectText = styled.div`
  z-index: 3;
  padding: 100px 30px 100px 0;
  color: #000;
  width: 100%;

  h4 {
    margin: 0;
  }

  p {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const ProjectImage = styled.img`
  border-radius: 30px;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 300px;
  z-index: 3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (max-width: 768px) {
    margin-bottom: 0;
    width: 100%;
  }
`;

const YellowButton = styled(Link)`
  text-decoration: none;
  padding: 10px 26px;
  background-color: var(--color-yellow);
  color: #fff;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d79647;
    transition: background-color 0.3s ease;
  }
`;
