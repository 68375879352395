import React from "react";
import styled from "styled-components";
import FacebookWhite from "../Assets/Facebook-white.svg";
import InstagramWhite from "../Assets/Instagram-white.svg";
import LinkedInWhite from "../Assets/LinkedIn-white.svg";

const footerLinks = [
  {
    id: "instagram",
    href: "https://www.instagram.com/appy.yo/",
    logo: InstagramWhite,
  },
  {
    id: "facebook",
    href: "https://www.facebook.com/appy.yo5?mibextid=LQQJ4d",
    logo: FacebookWhite,
  },
  {
    id: "linkedin",
    href: "https://www.linkedin.com/company/appy-yo/",
    logo: LinkedInWhite,
  },
];

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLinks>
        {footerLinks.map((link) => (
          <SocialLink href={link.href} key={link.id + '_footer'}>
            <img src={link.logo} />
          </SocialLink>
        ))}
      </SocialLinks>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  padding: 2.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const SocialLink = styled.a`
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    background-color: var(--color-bg-light);
  }
`;

export default Footer;
