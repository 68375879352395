import { createGlobalStyle } from "styled-components";
import Rubik from "../src/Assets//Fonts/Rubik-VariableFont_wght.ttf";
import RubikMonoOneRegular from "../src/Assets//Fonts/RubikMonoOne-Regular.ttf";
import Quicksand from "../src/Assets/Fonts/Quicksand-VariableFont_wght.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'RubikMonoOneRegular';
    src: url(${RubikMonoOneRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Rubik';
    src: url(${Rubik}) format('truetype');
  }

  @font-face {
    font-family: 'Quicksand';
    src: url(${Quicksand}) format('truetype');
  }

  :root {
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-yellow: #E3973E; 
    --color-yellow: #e2aa65; 
    --color-violet: #6219C6;
    --color-violet-hover: #4a07a6;
    --color-gray: #333647;
    --color-soft-violet: #5553A4;
    --color-soft-violet-hover: #423f7a;
    --color-purple: #908ed7;
    --color-bg-light: #959ab2;
    --color-bg-dark: #26262f;
  }

  * {
    box-sizing: border-box;
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Quicksand', sans-serif;
    font-size: 36px;
    margin: 0;
    padding: 0;
    background: var(--color-gray);
    color: var(--color-white);
  }
`;

export default GlobalStyle;
