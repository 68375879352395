import styled from "styled-components";

const Button = styled.button`
  width: 200px;
  height: 57px;
  border-radius: 30px;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--color-soft-violet);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px 0;

  &:hover {
    background-color: var(--color-soft-violet-hover);
  }

  @media (max-width: 767px) {
    width: 140px;
    height: 35px;
    font-size: 16px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 180px;
    height: 40px;
    font-size: 18px;
  }
`;

const CenteredButton = ({ onClick, buttonText }) => {
  return <Button onClick={onClick}>{buttonText}</Button>;
};

export default CenteredButton;
