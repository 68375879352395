import styled from "styled-components";
import FullLogo_Transparent from '../../Assets/FullLogo_Transparent.png';

export default function SectionOne() {
  return (
    <Container>
      <Header>
        <h2>FOR ALL YOUR DIGITAL</h2>
        <h1>TRANSFORMATION</h1>
        <h1>NEEDS</h1>
      </Header>
      <Image src={FullLogo_Transparent} alt="Logo" />
    </Container>
  );
}

const Container = styled.section`
  margin: 0 auto;
  height: calc(100vh - 8rem);
  width: 80%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 401px) and (max-width: 768px) {
    gap: 30px;
  }

  @media (max-width: 1023px) {
    height: calc(100vh - 6.5rem);
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
`;

const Header = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-family: 'RubikMonoOneRegular', sans-serif;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: -2px;
    margin: 0;
  }

  h2 {
    font-family: 'Rubik', sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    color: #b5b5b5;
    margin: 0;
  }

  @media (max-width: 400px) { 
    width: 100%;
    text-align: center;

    h1 {
      font-size: 1.75rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 401px) and (max-width: 768px) {
    width: 100%;
    text-align: center;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    width: 100%;
    text-align: center;

    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;

const Image = styled.img`
  width: 30%;

  @media (max-width: 400px) {
    width: 80%;
  }

  @media (min-width: 401px) and (max-width: 1023px) {
    width: 50%;
  }
`;