import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ButtonMore from "../ButtonMore";
import ProjectDetails from "./ProjectDetails";

const SectionFive = ({ projectsData }) => {
  const navigate = useNavigate();

  // Get the first project from the projectsData array
  const firstProject = projectsData[0];

  return (
    <Container>
      <DetailsContainer>
        <TextContainer>
          <h2>Browse our most recent projects</h2>
          <p>
            View the most recent innovative projects brought to life with
            Appy.Yo's technology.
          </p>
        </TextContainer>
        <ProjectWrapper>
          <ProjectDetails project={firstProject} />
        </ProjectWrapper>
      </DetailsContainer>
      <ButtonMore
        buttonText="Browse here"
        onClick={() => navigate("/projects")}
      />
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-color: var(--color-white);
  color: var(--color-black);
  padding: 4rem 0;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    flex-direction: column;
    width: 80%;
  }
`;

const TextContainer = styled.div`
  text-align: left;
  width: 40%;
  p {
    font-size: 2rem;
  }

  @media (max-width: 1200px) {
    text-align: center;
    width: 100%;
  }
`;

const ProjectWrapper = styled.div`
  width: 60%;

  @media (max-width: 1200px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export default SectionFive;
