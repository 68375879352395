import ImgShopful from "../Assets/shopful.png";
import ImgWevibe from "../Assets/wevibe.jpg";
import ImgPoste from "../Assets/1702056565017.jpg";
import ImgCookful from "../Assets/1702056505092.jpg";

const projectsData = [
  {
    title: "Cookful",
    description: "Cookful revolutionizes cooking with limited ingredients by generating curated recipes based on what's in your kitchen. It minimizes food waste, fosters creativity, and offers a diverse range of recipes for an elevated cooking experience.",
    image:ImgCookful ,
    link: "",
    showVisitButton: true
  },
  {
    title: "Poste",
    description: "Poste is a centralised platform that lets you save and organise useful posts from multiple social media platforms like Facebook, Instagram, Twitter, TikTok, and email. With Poste, you can easily find and share your saved posts with friends, making it easier to keep track of important information.",
    image: ImgPoste,
    link: "",
    showVisitButton: true
  },
  {
    title: "Shopful",
    description: "Shopful is the ultimate solution for last-minute shopping and fashion lovers who enjoy trying on clothes before buying. With a simple search for your desired product and radius, Shopful provides a list of all the nearby stores that carry the product.",
    image: ImgShopful,
    link: 'https://shopful-appyyo.herokuapp.com/',
    showVisitButton: true
  },
  {
    title: "WeVibe",
    description: "WeVibe reimagines the traditional online dating experience, providing a platform that values genuine human connections over swipes and superficial judgments. By allowing users to engage in a four-minute conversation before deciding whether to continue, WeVibe creates a space for authentic interactions and meaningful relationships.",
    image: ImgWevibe,
    link: "",
    showVisitButton: true
  },

  // add 2 more cards to projects
  {
    title: "Coming Soon",
    description: "",
    image:undefined,
    link: "",
    showVisitButton: false
  },

  {
    title: "Coming Soon",
    description: "",
    image: undefined,
    link: "",
    showVisitButton: false
  },
];

export default projectsData;
